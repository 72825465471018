<template>
  <div class="order">
    <!-- <navBar :title="title"></navBar> -->
    <!-- 订单列表 -->

    <div :key="index" class="order-lists" v-for="(item,index) in orderInfo">
      <div class="image">
        <img :src="pubilckPath + '/' + item.product.bigFilePath" alt />
      </div>
      <div class="order-info">
        <div>
          <span>{{item.product.productName}}</span>
          <span>¥{{ item.price }}/{{ item.product.productUnits }}</span>
          <span @click="deleteOrder(index)">
            <van-icon color="#7c7575" name="delete-o" />
          </span>
        </div>
        <div>{{item.product.productDesc}}</div>
        <div>
          <span>¥{{item.totalPrice | reserve2 }}</span>
          <van-stepper
            :min="item.product.moq"
            @change="step(item.orderNumber, item.product, index)"
            button-size="18"
            input-width="46px"
            v-model="item.orderNumber"
          />
        </div>
      </div>
    </div>

    <div class="order-desc">
      <div>
        <span>合计金额:</span>
        <span>{{totalPrice | reserve2}}</span>
      </div>
      <div>
        <span>运费:</span>
        <span>{{freight}}</span>
      </div>
      <div>
        <span>到付:</span>
        <input type="checkbox" v-model="checked" :disabled="checkStatus"/>
      </div>
      <div class="text-desc">
        <span>备注:</span>
        <textarea id name placeholder="请输入备注" rows="3" v-model="message"></textarea>
      </div>
    </div>

    <div class="bill-info infos">
      <div class="title" @click="jump('bill')">
        <span>发票信息</span>
        <van-icon name="edit" />
      </div>
      <div class="name common">
        <span>{{companyInvoice[billIndex].name }}</span>
        <span>{{companyInvoice[billIndex].creditCode }}</span>
      </div>
      <div class="address common">
        <span>{{ companyInvoice[billIndex].registerAddress }}</span>
        <span>{{ companyInvoice[billIndex].phone }}</span>
      </div>
      <div class="bank common">
        <span>{{ companyInvoice[billIndex].accountOpening }}</span>
        <span>{{ companyInvoice[billIndex].bankAccount }}</span>
      </div>
    </div>

    <div class="mail-info infos">
      <div class="title" @click="jump('mail')">
        <span>收货人信息</span>
        <van-icon name="edit" />
      </div>
      <div class="address">{{ receiptMessage[mailIndex].address }}</div>
      <div class="user common">
        <span>{{ receiptMessage[mailIndex].name }}</span>
        <span>{{ receiptMessage[mailIndex].phone }}</span>
      </div>
    </div>

    <div class="infos">
      <div class="title">
        <span>收款账号</span>
      </div>
      <div>
        <div>北京修文印刷技术有限公司</div>
        <div>招商银行股份有限公司北京北三环支行 &nbsp; 110917960410501</div>
      </div>
    </div>

    <!-- <div class="order-box">
      <div :key="index" class="order-list" v-for="(item, index) in orderInfo">
        <div class="order-list-item">
          <div class="name">名称:{{ item.product.productName }}</div>
          <div class="price">单价:{{ item.price }}元</div>
          <div class="total">共计:{{ item.totalPrice | reserve2 }}元</div>
        </div>
        <div class="order-list-item">
          <div class="number">
            <span>购买数量:</span>
            <van-stepper
              :min="item.product.moq"
              @change="step(item.orderNumber, item.product, index)"
              button-size="22"
              input-width="46px"
              theme="round"
              v-model="item.orderNumber"
            />
          </div>
          <div class="unit">单位:{{ item.product.productUnits }}</div>
          <div class="delete">
            <van-button @click="deleteProduct(index)" type="info">删除</van-button>
          </div>
        </div>
      </div>
      <p class="freight">
        <span>运费:{{ freight }}</span>
        <span class="checked">
          <input type="checkbox" v-model="checked" />
          到付
        </span>
      </p>
      <p class="totals">合计:{{ totalPrice | reserve2 }}元</p>
      <p class="totals">应付:{{ (totalPrice + freight) | reserve2 }}</p>
      <div class="note">
        <div>备注:</div>
        <div>
          <van-field autosize placeholder="请输入备注" rows="1" type="textarea" v-model="message" />
        </div>
      </div>
    </div>
    <!-- 发票信息 -->
    <!-- <div class="invoice-box">
      <div>
        <van-row class="invoice-message">
          <van-col span="12">发票信息</van-col>
          <van-col offset="4" span="8">
            <van-button @click="invoiceChange" type="info">修改</van-button>
          </van-col>
        </van-row>
        <van-row class="invoice-info" v-if="companyInvoice">
          <van-col span="24">
            {{ companyInvoice[indexs].name }}
            {{ companyInvoice[indexs].creditCode }}
          </van-col>
          <van-col span="24">
            {{ companyInvoice[indexs].registerAddress }}
            {{ companyInvoice[indexs].phone }}
          </van-col>
          <van-col span="24">
            {{ companyInvoice[indexs].accountOpening }}
            {{ companyInvoice[indexs].bankAccount }}
          </van-col>
        </van-row>
      </div>
    </div> -->

    <!-- 收货人信息 -->
   <!-- <div class="invoice-box">
      <div>
        <van-row class="invoice-message">
          <van-col span="12">收货人信息</van-col>
          <van-col offset="4" span="8">
            <van-button @click="receiptChange()" type="info">修改</van-button>
          </van-col>
        </van-row>
        <van-row class="invoice-info" v-if="receiptMessage">
          <van-col span="24">{{ receiptMessage[addressIndex].address }}</van-col>
          <van-col span="24">
            {{ receiptMessage[addressIndex].name }}
            {{ receiptMessage[addressIndex].phone }}
          </van-col>
        </van-row>
      </div>
    </div> -->

    <!-- 收款账号 -->
    <!-- <div class="invoice-box">
      <div>
        <van-row class="invoice-message">
          <van-col span="24">收款账号</van-col>
        </van-row>
        <van-row class="invoice-info">
          <van-col span="24">北京修文印刷技术有限公司</van-col>
          <van-col span="24">招商银行股份有限公司北京北三环支行 &nbsp; 110917960410501</van-col>
        </van-row>
      </div>
    </div> -->

    <!-- 确认订单 -->
    <div class="invoice-box footer">
      <div>
        <van-row class="invoice-message-bottom" justify="space-between" type="flex">
          <van-col span="8">¥{{ (totalPrice + freight) | reserve2 }}</van-col>
          <van-col span="8">
            <van-button @click="confirmOrder()" type="info">{{againOrder ? '再来一单':'确认订单'}}</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '../navBar'
import utils from '../../../assets/js/utils'
import { Dialog } from 'vant';
import { Notify , Toast} from 'vant';
export default {
  name: '',
  data () {
    return {
      title: {
        name: '我的订单',
        status: true,
      },
      orderInfo: [],
      orderNum: 1,
      totalPrice: 0,
      message: '',
      companyInvoice: '',
      receiptMessage: '',
      againOrder: false,
      checked: false,
      address: "",
      checkStatus:false

    }
  },
  components: {
    navBar,
  },
  created () {
    this.getOrderInfo();

    // 查询企业发票信息
    let id = this.userInfo.id;
    this.$request.get(this.$api.billInfo, { id: id }).then(res => {
      console.log("2ee233333333", res);
      this.companyInvoice = res.data;
      // 历史订单页 所需要显示数据。（发票信息）
      if (this.$route.query.enterpriseAddress.id) {
        console.log('this.$route.query.enterpriseAddress.id', this.$route.query.enterpriseAddress.id);

        let billId = this.$route.query.enterpriseInfo.id;
        let that = this;
        this.companyInvoice.forEach((item, index) => {
          if (item.id == billId) {
            that.$store.state.billIndex = index
          }
        })

      }
    });

    // 查询收货人信息

    this.$request.get(this.$api.billAddress, { id: id }).then(res => {
      console.log("2ee2444444444444", res);
      this.receiptMessage = res.data;
      this.address = res.data[0].address

      //历史订单页 所需要显示数据。（邮寄地址）
      if (this.$route.query.enterpriseAddress.id) {
        let addressId = this.$route.query.enterpriseAddress.id;
        let that = this;
        this.receiptMessage.forEach(function (value, index, array) {
          if (value.id == addressId) {
            that.$store.state.mailIndex = index
            that.address = res.data[index].address

          }
        });
      }
    });


    console.log('this.$route.query ', this.$route.query, this.companyInvoice);
    console.log('this.companyInvoice ', this.companyInvoice);
  },

  watch: {
    address (newValue, oldValue) {
      console.log('com11xin', newValue);
      console.log('com12', oldValue);

      let str = "北京"
      console.log('com1m', newValue.search(str) != -1);
      if (newValue.search(str) != -1) {
        console.log('com15');
        this.freight = 15
      } else {
        console.log('com125');
        this.freight = 25
        this.$forceUpdate()
      }
    },
    checked (newValue, oldValue) {
      if (newValue) {
        this.freight = 0
      }
    },
  },

  computed: {
    freight () {
      if (this.totalPrice >= 300) {
        // this.checkStatus = true
         let str = this.address.substring(0, 4)
         if (this.checked) {
           
          return 0;
        }

        if(str.search('宁夏') !=-1 || str.search('甘肃') !=-1 || str.search('广西') !=-1 || str.search('贵州') !=-1 || str.search('海南') !=-1 || str.search('青海') !=-1 || str.search('云南') != -1){

          return this.totalPrice*0.06

        }else if(str.search('西藏') !=-1 || str.search('新疆') != -1){
          return this.totalPrice*0.08

        }else{
          return 0
        }


        // return 0;
      } else {
        this.checkStatus = false

        if (this.checked) {
          return 0;
        }

        let str = this.address.substring(0, 4)
        console.log('com1nnnn', str.substring(0, 4), this.address ,);

        if (str.search('北京') != -1) {
          return 15;
        } else if(str.search('宁夏') !=-1 || str.search('甘肃') !=-1 || str.search('广西') !=-1 || str.search('贵州') !=-1 || str.search('海南') !=-1 || str.search('青海') !=-1 || str.search('云南') != -1){
          return 30

        }else if(str.search('西藏') !=-1 || str.search('新疆') != -1){
          return 40;

        }else{
          return 25
        }

        // return Math.ceil(this.totalPrice * 0.08) > 10
        //   ? Math.ceil(this.totalPrice * 0.08)
        //   : 10;

      }

    },
    userInfo () {
      const token = JSON.parse(window.sessionStorage.getItem("user-token"));
      return token.enterpriseUserDTO;
    },
    indexs () {
      return this.$store.state.indexs;
    },
    addressIndex () {
      this.address = this.receiptMessage[this.$store.state.mailIndex].address;
      return this.$store.state.mailIndex;
    },


    // 选择发票信息
    billIndex(){
      return this.$store.state.billIndex;
    },
      // 选择邮寄信息
    mailIndex(){
      console.log('进来了');
      
      this.address = this.receiptMessage[this.$store.state.mailIndex].address;

      return this.$store.state.mailIndex;
    }


  },



  filters: {
    // 保留两位小数
    reserve2 (num) {
      return utils.reserve2(num)
    },
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'historyPage') {
      next((vm) => {
        vm.againOrder = true;
      })

    } else {
      next((vm) => {
        vm.againOrder = false;
      })
    }
    // next(vm => vm.setData(err, 12134)); // err 与 12134 是随便传的值， 可忽略
  },
  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = true;
    next()
  },

  mounted () {



  },
  methods: {
    //获取订单列表数据
    getOrderInfo () {
      console.log('123123123', this.orderInfo)

      this.orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))
      console.log('123123123', this.orderInfo)
      this.totalPrice = 0
      if (this.orderInfo.length > 0) {
        this.orderInfo.forEach((item) => {
          item.totalPrice = item.orderNumber * item.price
          this.totalPrice += parseFloat(item.totalPrice)
        })
      }
      console.log('this.totalPrice', this.totalPrice)
    },
    // 删除产品
    deleteProduct (index) {
      console.log('orderInfo', this.orderInfo, index);
      this.orderInfo.splice(index, 1)
      sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
      console.log('orderInfo', this.orderInfo);
      this.getOrderInfo()
    },
    //改变数量时获取相对应的单价
    step (num, product, index) {
      var inputNum = num //输入款默认值
      var moq = product.moq //订购不能低于的数量
      var beginNum = product.productPrices[0].productNumber //初始价格的最低数量
      // 订购数量不得低于 基准值
      if (moq != 0) {

        if (num < moq) {
          this.$toast(`起订数量为${moq}`)
          this.orderInfo[index].orderNumber = moq
          this.$forceUpdate()
        }

      }
      if (inputNum <= beginNum) {
        this.orderInfo[index].price = product.productPrices[0].price
      }
      if (product.productPrices.length > 1 && inputNum >= beginNum) {
        for (var i = 0; i < product.productPrices.length; i++) {
          if (i < product.productPrices.length) {
            if (i + 1 == product.productPrices.length) {
              if (inputNum >= product.productPrices[i].productNumber) {
                this.orderInfo[index].price = product.productPrices[i].price
              }
            }

            if (
              i + 1 < product.productPrices.length &&
              i + 1 !== product.productPrices.length
            ) {
              if (
                inputNum >= product.productPrices[i].productNumber &&
                inputNum < product.productPrices[i + 1].productNumber
              ) {
                this.orderInfo[index].price = product.productPrices[i].price
              }
            }
          }
        }
      }
      console.log('sess', JSON.parse(sessionStorage.getItem('orderInfo')))
      this.reSet()
    },
    // 删除单个商品
    deleteOrder(index){
      if(this.orderInfo.length > 0){
        this.orderInfo.splice(index,1)
        if(this.orderInfo.length<=0){
            Toast.fail('请重新选择');
            setTimeout(() => {
              this.$router.push('/mobileIndex')
            },1000)
        }
      }
    },
    // 修改发票/邮寄信息
    jump(str){
      this.$store.state.navTitle = str;
      this.$router.push('/infoPage')
    },
    //将存入本地的订单列表进行同步
    reSet () {
      sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
      this.getOrderInfo()
    },
    // 到发票信息页
    invoiceChange () {
      this.$router.push({
        name: 'changePage',
        params: { title: '发票信息' }
      })
      sessionStorage.setItem('invoiceTitle', '发票信息')
      this.$store.state.billOrAddress = true;
      sessionStorage.setItem('billOrAddress', JSON.stringify(true))
      console.log('iiiiiiii', this.indexs);
      this.$store.state.redioActive = this.indexs
      sessionStorage.setItem('redioActive', JSON.stringify(this.indexs))

    },
    // 收货信息页面
    receiptChange () {
      this.$router.push({
        name: 'changePage',
        params: { title: '收货' }
      })
      sessionStorage.setItem('invoiceTitle', '收货信息')
      this.$store.state.billOrAddress = false;
      sessionStorage.setItem('billOrAddress', JSON.stringify(false))
      this.$store.state.redioActive = this.addressIndex
      sessionStorage.setItem('redioActive', JSON.stringify(this.addressIndex))

    },
    // 确认订单
    confirmOrder () {
      if (this.orderInfo.length <= 0) {
        Notify({ type: 'danger', message: '购物列表为空!' });

      } else {
        Dialog.confirm({
          title: '确认信息',
          message: '请注意开票信息和收货人信息，有错误可点击修改。没有问题请点击确认以提交订单。',
        })
          .then(() => {

            if (this.orderInfo.length > 0) {
              let data = {
                freight: this.freight,
                describes: this.message,
                enterpriseUser: {
                  id: this.$store.state.userId
                },
                enterpriseInfo: {
                  id: this.companyInvoice[this.billIndex].id
                },
                enterpriseAddress: {
                  id: this.receiptMessage[this.mailIndex].id
                },
                orderDetails: this.orderInfo,
                orderAmount: this.totalPrice + this.freight
              }
              this.$request.post(this.$api.confirmOrder, data).then(res => {
                console.log('resss', res);
                if (res.status == 200) {
                  this.$toast('提交成功，请您关注物流信息')
                  this.$router.push('/mobileIndex')
                }
              }).catch(err => {
                console.log('err', err);
              })
            } else {

            }

          })
          .catch(() => {
            console.log('chulaile');

          });
      }

      console.log('22', this.orderInfo);


    }
  },
}
</script>

<style lang="less" scoped>
body {
  padding-top: 0 !important;
}
.order {
  padding: 10px;
  padding-bottom: 50px;
}
.order-box {
  padding: 10px;
  color: #fff;
  border-radius: 3px;
  background-color: rgba(40, 49, 73);
  .order-list {
    background-color: rgba(64, 75, 105);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    .order-list-item {
      width: 50%;
      > div {
        margin-top: 10px;
      }
      .number {
        display: flex;
        justify-content: space-between;
        /deep/.van-stepper {
          > button {
            border: 0.0625rem solid #ffffff;
            color: #000;
            background-color: #ffffff;
          }
          > input {
            color: #fff;
          }
        }
      }
      .delete {
        display: flex;
        justify-content: flex-end;
        button {
          width: 100%;
          border-radius: 25px;
        }
      }
    }
  }
  .freight {
    padding: 0 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    > .checked {
      display: flex;
      align-items: center;
    }
  }
  .totals {
    padding: 0 10px;
    margin-top: 10px;
  }
  .note {
    padding: 0 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    > div {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}

.footer {
  height: 49px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  border-top: 1px solid #cccccc;
  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #fff !important;
  }

  .invoice-message-bottom {
    width: 100%;
     display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.invoice-box {
  // padding: 10px;

  // color: #fff;
  // border-radius: 3px;
  // margin-top: 10px;
  // background-color: rgba(40, 49, 73);
  > div {
    background-color: rgba(64, 75, 105);
  }
  .van-row {
    // padding: 10px;
  }
  .invoice-message {
    .van-col {
      height: 49px;
      
      &:nth-child(1) {
        line-height: 49px;
        font-size: 18px;
      }
      &:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        button {
          width: 100%;
          // border-radius: 25px;
        }
      }
    }
  }
  .invoice-message-bottom {
    .van-col {
      margin-left: 20px;
      height: 49px;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        line-height: 49px;
        color: red;
        font-weight: bold;
      }
      &:nth-child(2) {
        button {
          // width: 100%;
          // border-radius: 25px;
          background-color:#409eff;
          border: none;
          height: 25px !important;
          padding: 15px 15px;
        }
      }
    }
  }
  .invoice-info {
    .van-col {
      margin-top: 10px;
    }
  }
}

//
.order-lists {
  display: flex;
  padding: 5px;
  background-color: #eeeeee;
  margin-bottom: 10px;
  > div {
    &:nth-child(1) {
      width: 100px;
      height: 90px;
      img {
        width: 100%;
      }
    }
  }
  .order-info {
    margin-left: 15px;
    // display: flex;
    // justify-content:space-between;
    flex: 1;
    // font-size: 14px;
    > div {
      &:nth-child(1) {
        // color: #cccccc;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        > span {
          &:nth-child(2) {
            color: #7c7575;
            font-size: 14px;
          }
        }
      }

      &:nth-child(2) {
        margin-top: 10px;
        font-size: 12px;
      }

      &:nth-child(3) {
        margin-top: 15px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        > span {
          color: red;
          font-weight: 600;
        }
      }
    }
  }
}

.order-desc {
  padding: 5px 10px;
  background-color: #eeeeee;
  font-size: 14px;
  > div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
    align-items: center;
  }
  .text-desc {
    border: none;
    align-items: flex-start !important;

    textarea {
      flex: 1;
      border: none;
      background-color: #eeeeee;
    }
  }
}
.infos {
  margin-top: 10px;
  background-color: #eeeeee;
  padding: 5px;
  font-size: 14px;

  .title {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }

  .common {
    font-size: 13px !important;
    span {
      margin-right: 10px;
    }
  }
}
</style>
